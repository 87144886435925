import {
  CreateJobOfferRequestDto,
  JobOfferCreationEntitiesResponseDto,
  JobOfferDetailsResponseDto,
} from "@/lib/interfaces/job-offer";
import CreateEditor from "../../lib/utils/richtext-editor";
import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { ContentState, EditorState, convertFromHTML, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Save, X } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { useUser } from "../../hooks/use-user";
import useApiRequest from "../../lib/hooks/useRequest";
import { Checkbox } from "../../ui/components/ui/checkbox";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../ui/components/ui/form";
import { Input } from "../../ui/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/components/ui/select";

const PositionEditor = () => {
  const [item, setItem] = useState<JobOfferDetailsResponseDto>();
  const [creationEntities, setCreationEntities] = useState<JobOfferCreationEntitiesResponseDto>();
  const { id } = useParams();
  const { apiRequest } = useApiRequest();

  const getItem = useCallback(async () => {
    const response = await apiRequest<JobOfferDetailsResponseDto>(`job-offers/${id}`, "GET");

    if (response.data) {
      setItem(response.data);
      setCreationEntities(response.data.selectableEntities as JobOfferCreationEntitiesResponseDto);
    }
  }, [apiRequest, id]);

  const getEntities = useCallback(async () => {
    const creationEntitiesResponse = await apiRequest<JobOfferCreationEntitiesResponseDto>(
      "job-offers/creation-entities",
      "GET"
    );
    if (creationEntitiesResponse.data) {
      setCreationEntities(creationEntitiesResponse.data);
    }
  }, [apiRequest]);

  useEffect(() => {
    // if there is an id, get the item
    if (id) {
      getItem();
    } else {
      getEntities();
    }
  }, [getEntities, getItem, id]);

  return (
    <Flex direction="column" padding="1rem 1.5rem" className="h-full">
      <Flex width="100%" justifyContent="space-between" margin="0 0 1.5em">
        <Heading size="lg">
          <Text as="span" className="font-normal">
            Stellenangebote
          </Text>{" "}
          &gt;{" "}
          <Text as="span" className="font-semibold">
            {id ? "Stelle bearbeiten" : "Neue Stelle anlegen"}
          </Text>
        </Heading>
      </Flex>
      <Flex className="px-8 py-4 rounded border border-gray-300 gap-24 h-full bg-white">
        {creationEntities && (
          <>
            {id ? (
              item && <PositionForm item={item} creationEntities={creationEntities} />
            ) : (
              <PositionForm creationEntities={creationEntities} />
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
};

const PositionForm = ({
  creationEntities,
  item,
}: {
  creationEntities: JobOfferCreationEntitiesResponseDto;
  item?: JobOfferDetailsResponseDto;
}) => {
  const { apiRequest } = useApiRequest();

  // const { fileUpload } = useFileUpload();
  const navigate = useNavigate();

  // define form schema with zod with the required fields - in the future we can add validation here
  const formSchema = z.object({
    title: z.string().min(3, { message: "Bitte geben Sie eine stellenbezeichnung ein" }),
    description: z.string().refine(
      () => {
        const htmlContentDescription = draftToHtml(convertToRaw(editorDescriptionState.getCurrentContent()));
        return htmlContentDescription.length > 7;
      },
      {
        message: "Bitte geben Sie eine Beschreibung ein",
      }
    ),
    applicantProfile: z.string().refine(
      () => {
        const htmlContentProfile = draftToHtml(convertToRaw(requirementsEditorState.getCurrentContent()));
        return htmlContentProfile.length > 2;
      },
      { message: "Bitte geben Sie ein Bewerberprofil ein" }
    ),
    employeeBenefits: z.string().refine(
      () => {
        const htmlContentBenefits = draftToHtml(convertToRaw(editorBenefitsState.getCurrentContent()));
        return htmlContentBenefits.length > 10;
      },
      {
        message: "Bitte geben Sie Arbeitgebervorteile ein",
      }
    ),
    employmentStart: z.string().min(3, { message: "Bitte geben Sie ein gültiges Datum ein" }),
    workingTimeModels: z.array(z.string()).nonempty({ message: "Bitte wählen Sie ein Arbeitszeitmodell aus" }),
    belongsToCompanyLocationId: z.string().min(3, { message: "Bitte wählen Sie einen Standort aus" }),
  });

  // define the form with react-hook-form and zodResolver using the form schema and setting the default values
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: item?.title || "",
      description: item?.description || "",
      applicantProfile: item?.applicantProfile || "",
      employeeBenefits: item?.employeeBenefits || "",
      employmentStart: item?.employmentStart || "",
      workingTimeModels: item?.belongsToWorkingTimeModels || [],
      belongsToCompanyLocationId: item?.belongsToCompanyLocationId || "",
    },
  });

  // define the onSubmit function to handle the form submission - executed when the submit button is clicked
  async function onSubmit(values: z.infer<typeof formSchema>) {
    const htmlContentDescription = draftToHtml(convertToRaw(editorDescriptionState.getCurrentContent()));
    const htmlContentBenefits = draftToHtml(convertToRaw(editorBenefitsState.getCurrentContent()));
    const htmlContentProfile = draftToHtml(convertToRaw(requirementsEditorState.getCurrentContent()));

    // create the request object with the form values
    const req: CreateJobOfferRequestDto = {
      title: values.title,
      description: htmlContentDescription,
      applicantProfile: htmlContentProfile,
      employeeBenefits: htmlContentBenefits,
      employmentStart: values.employmentStart,
      belongsToWorkingTimeModels: values.workingTimeModels,
      belongsToCompanyLocationId: values.belongsToCompanyLocationId,
    };

    if (item) {
      // if the item exists, update the item with a PATCH request
      await apiRequest(`job-offers/${item._id}`, "PATCH", {
        body: req,
        toast: { toastText: "Stellenangebot wurde erfolgreich aktualisiert" },
      });
    } else {
      // if the item does not exist, create a new item with a POST request
      await apiRequest("job-offers", "POST", {
        body: req,
        toast: { toastText: "Stellenangebot wurde erfolgreich erstellt" },
      });
    }
    // redirect to the locations page after the item has been created or updated
    navigate("/stellenangebote");
  }

  const convertHtmlToEditorState = (html: string) => {
    const blocksFromHTML = convertFromHTML(html);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    return EditorState.createWithContent(contentState);
  };

  const defaultContent = ""; // Your default content as HTML

  const [editorDescriptionState, setEditorDescriptionState] = useState(() => convertHtmlToEditorState(defaultContent));
  const [editorBenefitsState, setEditorBenefitsState] = useState(() => convertHtmlToEditorState(defaultContent));
  const [requirementsEditorState, setRequirementsEditorState] = useState(() =>
    convertHtmlToEditorState(defaultContent)
  );

  useEffect(() => {
    if (item) {
      setEditorDescriptionState(convertHtmlToEditorState(item.description));
      setEditorBenefitsState(convertHtmlToEditorState(item.employeeBenefits));
      setRequirementsEditorState(convertHtmlToEditorState(item.applicantProfile));
    } else {
      setEditorBenefitsState(convertHtmlToEditorState(creationEntities.employeeBenefitsTemplate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { user } = useUser();
  const isEditable =
    (user?.permissionCompanyLocations === "FULL" ||
      user?.permissionCompanyLocations === "WRITE" ||
      user?.permissionOrganizationAdmin) &&
    (item ? item.isEditable : true);

  // the shadcn form component with the form fields and the submit button
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full flex gap-16 h-full bg-white">
        <Flex className="gap-16 h-full">
          <Box className="w-1/2">
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem className="flex gap-4 mb-4 w-full">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                    Stellenbezeichnung<span className="text-primary">*</span>
                  </FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Input
                        placeholder="Name"
                        {...field}
                        disabled={item ? (isEditable ? !item.titleEditable : true) : !isEditable}
                      />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="workingTimeModels"
              render={({ field }) => (
                <FormItem className="flex gap-4 mb-4 w-full">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                    Arbeitszeitmodell<span className="text-primary">*</span>
                  </FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <div className="flex flex-col gap-2">
                        {creationEntities.workingTimeModels.map((model) => (
                          <div className="flex gap-2" key={model.id}>
                            <Checkbox
                              id={model.id}
                              name={model.id}
                              checked={field.value?.includes(model.id)}
                              onCheckedChange={(checked) => {
                                return checked
                                  ? field.onChange([...field.value, model.id])
                                  : field.onChange(field.value?.filter((value) => value !== model.id));
                              }}
                              disabled={!isEditable}
                            />
                            <div className="grid gap-1.5 leading-none">
                              <label
                                htmlFor={model.id}
                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                              >
                                {model.value}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="belongsToCompanyLocationId"
              render={({ field }) => {
                return (
                  <FormItem className="flex gap-4 mb-4 w-full">
                    <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                      Standort<span className="text-primary">*</span>
                    </FormLabel>
                    <Box className="w-full">
                      <FormControl>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                          disabled={item ? !item.companyLocationEditable || !isEditable : false}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Wählen Sie den Standort aus" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {creationEntities.companyLocations.map((location) => (
                              <SelectItem key={location._id} value={location._id}>
                                {location.value}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </Box>
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="employmentStart"
              render={({ field }) => {
                const { value, ...restField } = field;

                return (
                  <FormItem className="flex gap-4 mb-4 w-full">
                    <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                      Stellenangebot gültig ab<span className="text-primary">*</span>
                    </FormLabel>
                    <Box className="w-full">
                      <FormControl>
                        <Input type="date" {...restField} value={value.split("T")[0]} disabled={!isEditable} />
                      </FormControl>
                      <FormMessage />
                    </Box>
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="employeeBenefits"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-3 w-full">
                  <FormLabel className="w-full font-bold mt-4">Arbeitgebervorteile / benefits</FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <CreateEditor
                        editorState={editorBenefitsState}
                        setEditorState={setEditorBenefitsState}
                        disabled={!isEditable}
                      />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
          </Box>
          <Box className="w-1/2 h-full flex flex-col justify-between">
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-3 w-full">
                  <FormLabel className="w-full font-bold mt-4">Beschreibung des Stellenangebots</FormLabel>
                  <Box className="w-full">
                    <CreateEditor
                      editorState={editorDescriptionState}
                      setEditorState={setEditorDescriptionState}
                      disabled={!isEditable}
                    />
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="applicantProfile"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-3 w-full">
                  <FormLabel className="w-full font-bold mt-4">
                    Anforderungen an den Bewerber (Bewerberprofil)
                  </FormLabel>
                  <Box className="w-full">
                    <CreateEditor
                      editorState={requirementsEditorState}
                      setEditorState={setRequirementsEditorState}
                      disabled={!isEditable}
                    />

                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
            <Flex className="gap-4 justify-end mt-auto">
              <Button
                colorScheme="primary"
                color="white"
                className="flex gap-2 p-2 text-white ml-auto"
                variant="solid"
                type="submit"
                isDisabled={form.formState.isSubmitting || (isEditable ? false : true)}
              >
                <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
                  <Save />
                </Box>
                <span>Speichern</span>
              </Button>
              <Button
                colorScheme="primary"
                className="flex gap-2 p-2 text-white"
                variant="outline"
                onClick={() => {
                  navigate("/stellenangebote");
                }}
              >
                <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
                  <X />
                </Box>
                <span>Schließen</span>
              </Button>
            </Flex>
          </Box>
        </Flex>

        {/* <FormField
            control={form.control}
            name="mailSender"
            render={({ field }) => (
              <FormItem className="flex gap-4 mb-4 w-full">
                <FormLabel className="max-w-[186px] w-full font-bold mt-4">Stellenangebote kopieren</FormLabel>
                <Box className="w-full">
                  <FormControl>
                    <Select>
                      <option value="Haupstandort">Haupstandort</option>
                      <option value="Zweigstelle">Zweigstelle</option>
                      <option value="Tagespflege">Tagespflege</option>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </Box>
              </FormItem>
            )}
          /> */}
      </form>
    </Form>
  );
};

export default PositionEditor;
