import { MailTemplateResponseDto, UpdateMailTemplateRequestDto } from "@/lib/interfaces/mail";
import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { ContentState, EditorState, Modifier, convertFromHTML, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Save } from "lucide-react";
import { Dispatch, SetStateAction, useState } from "react";
import { useUser } from "../../hooks/use-user";
import useApiRequest from "../../lib/hooks/useRequest";
import CreateEditor from "../../lib/utils/richtext-editor";
import { Input } from "../../ui/components/ui/input";
import Placeholder from "./Placeholder";

const FirstTabPanel = ({
  mailTemplate,
  placeholders,
  setReload,
}: {
  mailTemplate: MailTemplateResponseDto | undefined;
  placeholders: string[];
  setReload: Dispatch<SetStateAction<boolean>>;
}) => {
  const { user } = useUser();

  const convertHtmlToEditorState = (html: string) => {
    const blocksFromHTML = convertFromHTML(html);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    return EditorState.createWithContent(contentState);
  };

  const [editorState, setEditorState] = useState(() => convertHtmlToEditorState(mailTemplate?.template || ""));
  const [subject, setSubject] = useState<string>(mailTemplate?.subject || "");

  const { apiRequest } = useApiRequest();

  const updateTemplate = async () => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);

    const htmlContent = draftToHtml(rawContentState);

    const req: UpdateMailTemplateRequestDto = {
      template: htmlContent,
      subject: subject,
    };

    await apiRequest(`mail-templates/${mailTemplate?._id}`, "PATCH", {
      body: req,
      toast: { toastText: "Email-Vorlagen wurde erfolgreich aktualisiert" },
    });
    setReload((old) => !old);
  };

  if (!user) return null;

  const isDisabled =
    user?.permissionMailTemplates !== "WRITE" &&
    user?.permissionMailTemplates !== "FULL" &&
    !user?.permissionOrganizationAdmin;

  return (
    <Flex className="p-8 rounded border border-gray-300 gap-8 flex-col h-full bg-white">
      <Flex className="gap-8 mb-6">
        <Box className="w-2/5">
          <Heading size="sm" className="font-bold mb-4">
            {mailTemplate?.title}
          </Heading>
          <Text>{mailTemplate?.description}</Text>
        </Box>
        <Box className="w-3/5">
          <div className="flex flex-col gap-3 w-full mb-5">
            <label className="w-full font-bold mt-4 text-md">Betreff</label>
            <div className="w-full">
              <Input
                type="text"
                placeholder="Betreff"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                disabled={isDisabled}
              />
            </div>
          </div>
          <Placeholder
            placeholders={placeholders}
            onPlaceholderClicked={(placeholder) => {
              if (isDisabled) return;

              const contentState = editorState.getCurrentContent();
              const selectionState = editorState.getSelection();

              const newContentState = Modifier.insertText(contentState, selectionState, `[${placeholder}]`);

              const newEditorState = EditorState.push(editorState, newContentState, "insert-characters");
              setEditorState(newEditorState);
            }}
          />
          <CreateEditor editorState={editorState} setEditorState={setEditorState} disabled={isDisabled} />
        </Box>
      </Flex>
      <Flex className="gap-4 justify-end mt-auto">
        <Button
          colorScheme="primary"
          color="white"
          className="flex gap-2 p-2 text-white"
          variant="solid"
          onClick={updateTemplate}
          isDisabled={isDisabled}
        >
          <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
            <Save />
          </Box>
          <span>Speichern</span>
        </Button>
      </Flex>
    </Flex>
  );
};

export default FirstTabPanel;
