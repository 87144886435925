import {
  CompanyLocationDetailsResponseDto,
  CompanyLocationResponseDto,
  CreateCompanyLocationDto,
} from "@/lib/interfaces/locations";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Flex,
  Heading,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { CheckIcon, Save, X } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import useApiRequest from "../../lib/hooks/useRequest";
import ImageUpload from "../../lib/utils/image-upload";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../ui/components/ui/form";
import { Input } from "../../ui/components/ui/input";
import { useUser } from "../../hooks/use-user";
import { CloseIcon } from "@chakra-ui/icons";

const LocationEditor = () => {
  const [item, setItem] = useState<CompanyLocationDetailsResponseDto>();
  const { id } = useParams();
  const { apiRequest } = useApiRequest();
  const [, setCreationEntities] = useState<any>();

  const getItem = useCallback(async () => {
    const response = await apiRequest<CompanyLocationDetailsResponseDto>(`company-locations/${id}`, "GET");
    if (response.data) {
      setItem(response.data);
    }
  }, [apiRequest, id]);

  const getEntities = useCallback(async () => {
    const creationEntitiesResponse = await apiRequest<any>("company-locations/creation-entities", "GET");
    if (creationEntitiesResponse.data) {
      setCreationEntities(creationEntitiesResponse.data);
    }
  }, [apiRequest]);

  useEffect(() => {
    // if there is an id, get the item
    if (id) {
      getItem();
    } else {
      getEntities();
    }
  }, [getEntities, getItem, id]);

  return (
    <Flex direction="column" padding="1rem 1.5rem" className="h-full">
      <Flex width="100%" justifyContent="space-between" margin="0 0 1.5em">
        <Heading size="lg">
          <Text as="span" className="font-normal">
            Standorte
          </Text>{" "}
          &gt;{" "}
          <Text as="span" className="font-semibold">
            {id ? "Standort bearbeiten" : "Neuer Standort"}
          </Text>
        </Heading>
        {/* <Button
          colorScheme="primary"
          color="white"
          className="flex gap-2 p-2 text-white"
          variant="solid"
          onClick={() => {
            navigate("/standorte/neu");
          }}
        >
          <Box
            className="text-primary"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            bg="white"
            boxSize="1rem"
          >
            <SmallAddIcon />
          </Box>
          <span>Standort hinzufügen</span>
        </Button> */}
      </Flex>
      <Flex className="px-8 py-4 rounded border border-gray-300 gap-24 h-full bg-white">
        {id ? item && <LocationForm item={item} /> : <LocationForm />}
      </Flex>
    </Flex>
  );
};

const LocationForm = ({ item }: { item?: CompanyLocationDetailsResponseDto }) => {
  const { apiRequest } = useApiRequest();
  const [id, setId] = useState<string>("");
  const [hasFileUpload, setHasFileUpload] = useState<boolean>(false);
  // const { fileUpload } = useFileUpload();
  const navigate = useNavigate();
  const { user } = useUser();
  const isEditable =
    (user?.permissionCompanyLocations === "FULL" ||
      user?.permissionCompanyLocations === "WRITE" ||
      user?.permissionOrganizationAdmin) &&
    (item ? item.isEditable : true);
  const debounceTimer = useRef<number | undefined>(undefined);
  const [mailIsValid, setMailIsValid] = useState<boolean>();
  const [mailCheckLoading, setMailCheckLoading] = useState(false);

  const telRegex = /^\+?(\d+|\([0-9]+\))([0-9]*[-()]?){5,13}\d$/;

  // define form schema with zod with the required fields - in the future we can add validation here
  const formSchema = z.object({
    title: z.string().min(3, {
      message: "Bitte geben Sie Ihren Namen ein (min 3 Zeichen)",
    }),
    description: z.string().min(1, { message: "Geben Sie einen Standorttyp ein" }),
    street: z.string().min(1, { message: "Straße und Hausnummer dürfen nicht leer sein" }),
    postCode: z.string().min(1, { message: "Die Postleitzahl darf nicht leer sein." }),
    placeName: z.string().min(1, { message: "Geben Sie bitte einen Ortsnamen ein" }),
    applicantsContactName: z.string().min(1, { message: "Geben Sie bitte einen Ansprechpartnernamen ein" }),
    // applicantsContactPhone: z.string().trim().replace(/\s/g, "").regex(telRegex, { message: "Invalid phone number" }),
    applicantsContactPhone: z
      .string()
      .trim()
      .transform((val) => val.replace(/\s/g, ""))
      .refine((val) => telRegex.test(val), {
        message: "Ungültige Telefonnummer. Geben Sie es in folgendem Format ein: +49123123123",
      }),
    applicantsContactMail: z.string().email({ message: "Bitte geben Sie eine Partner-Kontakt-Email ein" }),
    mailSender: z
      .string()
      .min(1, { message: "Bitte geben Sie die geschäftliche Email-Adresse eines Partners ein" })
      .refine(
        (value) => {
          const mailRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !mailRegex.test(value);
        },
        { message: "Bitte geben Sie keine Email Adresse an" }
      ),
  });

  // define the form with react-hook-form and zodResolver using the form schema and setting the default values
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: item?.title ?? "",
      description: item?.description ?? "",
      street: item?.street ?? "",
      postCode: item?.postCode ?? "",
      placeName: item?.placeName ?? "",
      applicantsContactName: item?.applicantsContactName ?? "",
      applicantsContactPhone: item?.applicantsContactPhone ?? "",
      applicantsContactMail: item?.applicantsContactMail ?? "",
      mailSender: item?.mailSenderPrefix ?? "",
    },
  });

  // const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  // const [errorMessage, setErrorMessage] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertShow, setAlertShow] = useState<boolean>(false);

  // const closeModal = () => {
  //   setIsErrorModalOpen(false);
  // };

  const onCloseAlert = () => {
    setAlertShow(false);
  };

  // define the onSubmit function to handle the form submission - executed when the submit button is clicked
  async function onSubmit(values: z.infer<typeof formSchema>) {
    // create the request object with the form values
    const req: CreateCompanyLocationDto = {
      title: values.title,
      description: values.description,
      street: values.street,
      postCode: values.postCode,
      placeName: values.placeName,
      applicantsContactName: values.applicantsContactName,
      applicantsContactPhone: values.applicantsContactPhone,
      applicantsContactMail: values.applicantsContactMail,
      mailSenderPrefix: values.mailSender,
    };

    let response;

    if (item) {
      // if the item exists, update the item with a PATCH request
      response = await apiRequest<CompanyLocationResponseDto>(`company-locations/${item._id}`, "PATCH", {
        body: req,
        toast: { toastText: "Standort wurde erfolgreich aktualisiert" },
      });
    } else {
      // if the item does not exist, create a new item with a POST request
      response = await apiRequest<CompanyLocationResponseDto>("company-locations", "POST", {
        body: req,
        toast: { toastText: "Standort wurde erfolgreich erstellt" },
      });
    }

    if (response.error) {
      // setErrorMessage((response.error as string) + response.status.toString());
      // setIsErrorModalOpen(true);
      setAlertMessage(response.error as string);
      setAlertShow(true);
    } else {
      // redirect to the locations page after the item has been created or updated
      if (!hasFileUpload) {
        navigate("/standorte");
      } else {
        setId(response.data?._id ?? "");
      }
    }
  }

  const checkMailValidity = useCallback(
    async (mail: string) => {
      if (mail === "") {
        setMailIsValid(false);
        return;
      }
      setMailCheckLoading(true);
      const response = await apiRequest<{ isAvailable: boolean }>(
        `company-locations/mail-validation?mailSenderPrefix=${mail}`,
        "GET"
      );
      if (response.data) {
        setMailIsValid(response.data.isAvailable);
      }
      setMailCheckLoading(false);
    },
    [apiRequest]
  );

  useEffect(() => {
    if (mailIsValid === false) {
      form.setError("mailSender", {
        message: "Email-Adresse ist schon vergeben",
      });
    } else {
      form.clearErrors("mailSender");
    }
  }, [form, mailIsValid]);

  // the shadcn form component with the form fields and the submit button
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full flex gap-16 h-full bg-white">
        <Box className="w-1/2">
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem className="flex gap-4 mb-4 w-full">
                <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                  Name des Standorts <span className="text-primary">*</span>
                </FormLabel>
                <Box className="w-full">
                  <FormControl>
                    <Input placeholder="Name" {...field} disabled={(item && !item.titleEditable) || !isEditable} />
                  </FormControl>
                  <FormMessage />
                </Box>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem className="flex gap-4 mb-4 w-full">
                <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                  Art des Standorts <span className="text-primary">*</span>
                </FormLabel>
                <Box className="w-full">
                  <FormControl>
                    <Input placeholder="Art des Standorts" {...field} disabled={!isEditable} />
                  </FormControl>
                  <FormMessage />
                </Box>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="street"
            render={({ field }) => (
              <FormItem className="flex gap-4 mb-4 w-full">
                <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                  Straße & Hausnummer <span className="text-primary">*</span>
                </FormLabel>
                <Box className="w-full">
                  <FormControl>
                    <Input placeholder="Straße & Hausnummer" {...field} disabled={!isEditable} />
                  </FormControl>
                  <FormMessage />
                </Box>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="postCode"
            render={({ field }) => (
              <FormItem className="flex gap-4 mb-4 w-full">
                <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                  PLZ <span className="text-primary">*</span>
                </FormLabel>
                <Box className="w-full">
                  <FormControl>
                    <Input placeholder="PLZ" {...field} disabled={!isEditable} />
                  </FormControl>
                  <FormMessage />
                </Box>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="placeName"
            render={({ field }) => (
              <FormItem className="flex gap-4 mb-4 w-full">
                <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                  Ort <span className="text-primary">*</span>
                </FormLabel>
                <Box className="w-full">
                  <FormControl>
                    <Input placeholder="Ort" {...field} disabled={!isEditable} />
                  </FormControl>
                  <FormMessage />
                </Box>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="applicantsContactName"
            render={({ field }) => (
              <FormItem className="flex gap-4 mb-4 w-full">
                <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                  Name Ansprechpartner für Bewerber <span className="text-primary">*</span>
                </FormLabel>
                <Box className="w-full">
                  <FormControl>
                    <Input placeholder="Name" {...field} disabled={!isEditable} />
                  </FormControl>
                  <FormMessage />
                </Box>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="applicantsContactPhone"
            render={({ field }) => (
              <FormItem className="flex gap-4 mb-4 w-full">
                <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                  Telefon Ansprechpartner für Bewerber <span className="text-primary">*</span>
                </FormLabel>
                <Box className="w-full">
                  <FormControl>
                    <Input placeholder="+49123123123" {...field} disabled={!isEditable} />
                  </FormControl>
                  <FormMessage />
                </Box>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="applicantsContactMail"
            render={({ field }) => (
              <FormItem className="flex gap-4 mb-4 w-full">
                <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                  Email Ansprechpartner für Bewerber <span className="text-primary">*</span>
                </FormLabel>
                <Box className="w-full">
                  <FormControl>
                    <Input placeholder="Email" {...field} disabled={!isEditable} />
                  </FormControl>
                  <FormMessage />
                </Box>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="mailSender"
            render={({ field }) => (
              <FormItem className="flex gap-4 mb-4 w-full">
                <FormLabel className="max-w-[186px] w-full font-bold mt-4">
                  Versand-Email für diesen Standort <span className="text-primary">*</span>
                </FormLabel>
                <Box className="w-full">
                  <FormControl>
                    <Flex className="w-full gap-2 items-center">
                      <Input
                        placeholder="Versand-Email"
                        {...field}
                        disabled={!isEditable}
                        onChange={(e) => {
                          field.onChange(e);
                          if (debounceTimer.current !== undefined) {
                            clearTimeout(debounceTimer.current);
                          }

                          debounceTimer.current = window.setTimeout(() => {
                            checkMailValidity(e.target.value);
                          }, 500);
                        }}
                      />
                      <Text className="mr-2">@kandidat.io</Text>
                      {mailCheckLoading && <Spinner />}
                      {mailIsValid && !mailCheckLoading && <CheckIcon className="text-green-500" />}
                      {mailIsValid === false && !mailCheckLoading && <CloseIcon className="text-red-500" />}
                    </Flex>
                  </FormControl>
                  <FormMessage />
                </Box>
              </FormItem>
            )}
          />
          {/* <FormField
            control={form.control}
            name="mailSender"
            render={({ field }) => (
              <FormItem className="flex gap-4 mb-4 w-full">
                <FormLabel className="max-w-[186px] w-full font-bold mt-4">Stellenangebote kopieren</FormLabel>
                <Box className="w-full">
                  <FormControl>
                    <Select>
                      <option value="Haupstandort">Haupstandort</option>
                      <option value="Zweigstelle">Zweigstelle</option>
                      <option value="Tagespflege">Tagespflege</option>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </Box>
              </FormItem>
            )}
          /> */}
        </Box>
        <Box className="w-1/2 h-full flex flex-col justify-between">
          {item && (!item.isEditable || !isEditable) ? (
            <>
              {item.imageUrl && (
                <div>
                  <img
                    src={item.imageUrl}
                    alt="Location"
                    className="rounded-lg w-full h-full border border-solid cursor-pointer"
                  />
                </div>
              )}
            </>
          ) : (
            <ImageUpload
              route={`company-locations/${item?._id ?? id}/image`}
              baseImg={item?.imageUrl ?? ""}
              defaultUpload={id !== "" ? true : false}
              onUpload={() => navigate("/standorte")}
              onFileAdded={() => setHasFileUpload(true)}
              heading="Foto des Standorts (empfohlen)"
            />
          )}
          <Flex className="gap-4 flex-col mt-auto">
            {alertShow && alertMessage && (
              <Alert status="error" marginBottom="2em" borderRadius=".5em" maxWidth="34rem" marginLeft="auto">
                <AlertIcon />
                <Box>
                  <AlertTitle>Es ist ein Fehler aufgetreten</AlertTitle>
                  <AlertDescription>{alertMessage}</AlertDescription>
                </Box>
                <CloseButton alignSelf="flex-start" position="relative" right={-1} top={-1} onClick={onCloseAlert} />
              </Alert>
            )}
            <Flex className="justify-end gap-4">
              <Button
                colorScheme="primary"
                color="white"
                className="flex gap-2 p-2 text-white ml-auto"
                variant="solid"
                type="submit"
                isDisabled={form.formState.isSubmitting || !isEditable}
              >
                <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
                  <Save />
                </Box>
                <span>Speichern</span>
              </Button>
              <Button
                colorScheme="primary"
                className="flex gap-2 p-2 text-white"
                variant="outline"
                onClick={() => {
                  navigate("/standorte");
                }}
              >
                <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
                  <X />
                </Box>
                <span>Schließen</span>
              </Button>
            </Flex>
            {/* <ErrorModal isOpen={isErrorModalOpen} onClose={closeModal} errorMessage={errorMessage} /> */}
          </Flex>
        </Box>
      </form>
    </Form>
  );
};

export default LocationEditor;
