import { EditorState } from "draft-js";
import { Dispatch, SetStateAction } from "react";
import type { EditorProps } from "react-draft-wysiwyg";
import { Editor } from "react-draft-wysiwyg";

// Type assertion for the Editor component
const DraftEditor = Editor as unknown as React.ComponentType<EditorProps>;

interface CreateEditorProps {
  editorState: EditorState;
  setEditorState: Dispatch<SetStateAction<EditorState>>;
  disabled?: boolean;
}

const CreateEditor = ({ editorState, setEditorState, disabled }: CreateEditorProps) => {
  const defaultOptions = {
    options: ["inline", "blockType", "list", "textAlign", "link", "remove", "history"],
    inline: { options: ["bold", "italic", "underline", "strikethrough", "monospace"] },
    blockType: { inDropdown: true },
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
    link: { inDropdown: true },
    emoji: { inDropdown: true },
    remove: { inDropdown: true },
    history: { inDropdown: true },
  };

  return (
    <DraftEditor
      editorState={editorState}
      wrapperClassName="settingsEditorWrapper"
      editorClassName="settingsEditorContent"
      toolbarClassName={disabled ? "settingsEditorToolbar" : ""}
      onEditorStateChange={setEditorState}
      readOnly={disabled ?? false}
      toolbar={defaultOptions}
    />
  );
};

export default CreateEditor;
